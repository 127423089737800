import React from 'react';
import Chip from 'material-ui/Chip';
import { Row, Col } from 'react-flexbox-grid';
import { UserPropType } from 'common/constants/PropTypes';
import UserAvatar from '../utils/UserAvatar';
import nl2br from '../../utils/nl2br';

const styles = {
  chipWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 5,
    marginBottom: 10,
  },
  chip: {
    margin: 4,
    cursor: 'pointer',
  },
};

const renderTagList = (tagList) => {
  const chips = tagList.map(t => (
    <Chip
      key={t}
      style={styles.chip}
      href={`/tags/${t}`}
    >
      {t}
    </Chip>
  ));

  return (
    <div style={styles.chipWrapper}>
      {chips}
    </div>
  );
};

const UserProfile = props => (
  <Row>
    <Col xs={12} sm={4} style={{ textAlign: 'center' }}>
      <UserAvatar user={props.user} size={120} />
    </Col>
    <Col xs={12} sm={8}>
      <p>{nl2br(props.user.profile)}</p>
      <div>{renderTagList(props.user.tag_list)}</div>
      {props.user.total_points !== null && (
        <div>
          Peer Ring ポイント：
          {props.user.total_points}
        </div>
      )}
    </Col>
  </Row>
);

UserProfile.propTypes = {
  user: UserPropType.isRequired,
};

export default UserProfile;
