import React from 'react';
import PropTypes from 'prop-types';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { MuiThemeProvider as V0MuiThemeProvider } from 'material-ui';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import spacing from 'material-ui/styles/spacing';
import withWidth, { SMALL, MEDIUM, LARGE } from 'material-ui/utils/withWidth';
import RaisedButton from 'material-ui/RaisedButton';
import { Row, Col } from 'react-flexbox-grid';

import { UserPropType, CategoryPropType, NotificationPropType } from 'common/constants/PropTypes';
import AppNavDrawer from './AppNavDrawer';
import NavTabs from './NavTabs';
import NavBar from './NavBar';
import EditDialogContainer from '../containers/EditDialogContainer';
import BriefMessage from '../containers/BriefMessage';
import theme from '../../constants/theme';

const muiTheme = createMuiTheme();

const muiThemeV0 = getMuiTheme(theme);

function getStyles(width) {
  const styles = {
    root: {
      paddingTop: 56,
      minHeight: 400,
    },
    content: {
      margin: 5,
      paddingBottom: 30,
    },
    contentWhenMedium: {
      margin: `10px ${spacing.desktopGutter * 3}px ${spacing.desktopGutter}px`,
    },
    contentInner: {
      maxWidth: 600,
      margin: '0 auto',
    },
    alert: {
      padding: '10px 20px 0',
    },
    buttonsContainer: {
      marginBottom: 10,
    },
    buttonsContainerRow: {
      margin: 0,
    },
    buttonsContainerSignIn: {
      paddingLeft: 0,
    },
    buttonsContainerSignUp: {
      paddingRight: 0,
    },
  };

  if (global.PeerRing.embeded) {
    styles.root.paddingTop = 0;
  }
  if (width === MEDIUM || width === LARGE) {
    styles.content = Object.assign(styles.content, styles.contentWhenMedium);
    styles.root.paddingTop = spacing.desktopKeylineIncrement;
  }

  return styles;
}

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      navDrawerOpen: false,
      editDialogOpen: false,
      // alert: props.alert,
      // notice: props.notice,
    };
    this.handleTouchTapLeftIconButton = this.handleTouchTapLeftIconButton.bind(this);
    this.handleChangeRequestNavDrawer = this.handleChangeRequestNavDrawer.bind(this);
    this.handleActionButton = this.handleActionButton.bind(this);
    this.handleColseEditDialog = this.handleColseEditDialog.bind(this);
    global.PeerRing.handleActionButton = this.handleActionButton;
  }

  getChildContext() {
    const { authenticityToken, user, categories } = this.props;
    return { authenticityToken, user, categories };
  }

  handleTouchTapLeftIconButton() {
    this.setState({ navDrawerOpen: !this.state.navDrawerOpen });
  }

  handleChangeRequestNavDrawer(open) {
    this.setState({ navDrawerOpen: open });
  }

  handleActionButton() {
    this.setState({ editDialogOpen: true });
  }

  handleColseEditDialog() {
    this.setState({ editDialogOpen: false });
  }

  renderSignInButtons() {
    const { user, width } = this.props;
    if (user || width > SMALL || global.PeerRing.embeded) {
      return null;
    }
    const styles = getStyles(width);

    return (
      <div style={styles.buttonsContainer}>
        <Row style={styles.buttonsContainerRow}>
          <Col xs={6} style={styles.buttonsContainerSignIn}>
            <RaisedButton
              key="ログイン"
              label="ログイン"
              href="/users/sign_in"
              fullWidth
            />
          </Col>
          <Col xs={6} style={styles.buttonsContainerSignUp}>
            <RaisedButton
              key="会員登録"
              label="会員登録"
              href="/users/sign_up"
              fullWidth
            />
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    const {
      children,
      width,
      user,
      alert,
      notice,
      popularTags,
      notifications,
      showPostActionButton,
    } = this.props;
    const { prepareStyles } = muiThemeV0;
    const { editDialogOpen } = this.state;
    let { navDrawerOpen } = this.state;
    const styles = getStyles(width);
    let docked = false;
    let showMenuIconButton = true;
    let navTbas = <NavTabs />;

    if (width === LARGE) {
      docked = true;
      navDrawerOpen = true;
      showMenuIconButton = false;
      navTbas = null;

      styles.root.paddingLeft = 256;
    }

    const messages = [];
    if (alert) {
      messages.push(alert);
    }
    if (notice) {
      messages.push(notice);
    }

    return (
      <MuiThemeProvider theme={muiTheme}>
        <V0MuiThemeProvider muiTheme={muiThemeV0}>
          <div>
            <NavBar
              user={user}
              notifications={notifications}
              width={width}
              muiTheme={muiThemeV0}
              showMenuIconButton={showMenuIconButton}
              handleActionButton={this.handleActionButton}
              handleTouchTapLeftIconButton={this.handleTouchTapLeftIconButton}
              popularTags={popularTags}
              showPostActionButton={showPostActionButton}
            />
            <div style={prepareStyles(styles.root)}>
              {navTbas}
              <div style={prepareStyles(styles.content)}>
                <div id="layoutContainer" style={prepareStyles(styles.contentInner)}>
                  {this.renderSignInButtons()}
                  {React.cloneElement(children, { width })}
                </div>
              </div>
            </div>
            <AppNavDrawer
              style={styles.navDrawer}
              docked={docked}
              onRequestChangeNavDrawer={this.handleChangeRequestNavDrawer}
              open={navDrawerOpen}
              popularTags={popularTags}
              width={width}
            />
            <EditDialogContainer
              user={user}
              open={editDialogOpen}
              onRequestClose={this.handleColseEditDialog}
            />
            <BriefMessage initialMessages={messages} />
          </div>
        </V0MuiThemeProvider>
      </MuiThemeProvider>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node,
  width: PropTypes.number.isRequired,
  user: UserPropType,
  authenticityToken: PropTypes.string.isRequired,
  alert: PropTypes.string,
  notice: PropTypes.string,
  categories: PropTypes.arrayOf(CategoryPropType).isRequired,
  notifications: PropTypes.arrayOf(NotificationPropType),
  popularTags: PropTypes.arrayOf(PropTypes.string).isRequired,
  showPostActionButton: PropTypes.bool,
};

Layout.defaultProps = {
  children: null,
  user: null,
  alert: '',
  notice: '',
  notifications: [],
  showPostActionButton: true,
};

Layout.childContextTypes = {
  authenticityToken: PropTypes.string,
  user: UserPropType,
  categories: PropTypes.arrayOf(CategoryPropType),
};

export default withWidth()(Layout);
