
const PeerRing = {};
PeerRing.ImgServer = 'https://i.peer-ring.com';
PeerRing.images = {
  logo_title: "/assets/logo_title-312e3008e745bfd2a0d8fa9aebfe3a67a6d620428de86e9b8f64b3510a8d2d7835ef16.png",
  logo_title_2x: "/assets/logo_title@2x-312e308945b474a78227d01c565897fa485f9431d321a11ff43fd1de170f9511e32e1f.png",
  logo_icon: "/assets/logo_icon-312e301e412d5b3a244e6318d850e4abde95cd8fa39933f192f86dc91e66f54e1307fd.png",
  logo_icon_2x: "/assets/logo_icon@2x-312e30b7fbaa6aaf5bf5c1c4f7ec85fcb45772ae5462c24492a011dbe6e80ad9a9236e.png",
  logo_icon120: "/assets/logo_icon120-312e30d246333a80f53687c5443057e5ccfaa11792661c984077d46ef36f9f5abcdb31.png",
  logo_icon120_2x: "/assets/logo_icon120@2x-312e304f7d65d0b813c6871da048a7281ddb1b232734a2475dd1d647b1a2398e1c6ab7.png",
  topImage: "/assets/top-image-312e30a06de58c5cbee62c42e97371fa0d5b54ef4a77c2499dffeea47eaa14a0455b6b.jpg",
  about: [
    "/assets/about01-312e303a953a96f3f0d28e32e77a2d57f90a85e493f5567b9aca06a1b27d2b71b8411f.jpg",
    "/assets/about02-312e306168f2fd9d6f1a859d29ed7efdcc6eca23edbda635f5126ffca30b2cbfde43fe.jpg",
    "/assets/about03-312e30a8d826fd93a5a7d933e4a5d1b661e984f2a2404577463d5480336b3128214448.jpg",
    "/assets/about04-312e30a7d7962fa9d0da96aa270114872b04edcba1d00e944da9878c9837e878b696d1.jpg",
    "/assets/about05-312e301a0b333db1f1630bca7e2c16fccdc368c122c78afe738b66bf0eb957e2c803e9.jpg",
  ],
  badge_download_on_appstore: "/assets/badge-download-on-the-app-store-jp-312e30988fe0a48015c5a56dac88172487a1f5cda96340b34d80fd94312d02e9f67865.svg",
  google_play_badge: "/assets/google-play-badge-312e309109ebbe6a617995eeb0d623a924ce362c112094a5e21bfcae42ea0852f47148.png",
};
PeerRing.RailsEnv = 'staging';

if (!global.PeerRing) {
  global.PeerRing = {};
}
global.PeerRing = Object.assign({}, global.PeerRing, PeerRing);
