import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab } from 'material-ui/Tabs';
import { grey500 } from 'material-ui/styles/colors';
import { UserPropType } from 'common/constants/PropTypes';
import UserProfile from '../users/UserProfile';
import PostsListContainer from '../containers/PostsListContainer';

const styles = {
  tabContainer: {
    marginTop: 10,
    marginBottom: 10,
  },
  userLogin: {
    paddingLeft: 5,
    fontSize: 'small',
    color: grey500,
  },
};

class UserPage extends React.Component {
  renderUserImage() {
    const { user } = this.props;
    return (
      <h2>
        {user.name}
        <wbr />
        <span style={styles.userLogin}>@{user.login || '????'}</span>
      </h2>
    );
  }

  renderTabs() {
    const { user, isSabako } = this.props;
    const display = this.props.display || 'diaries';

    return (
      <div>
        <div style={styles.tabContainer}>
          <UserProfile user={user} />
        </div>
        <Tabs value={display}>
          <Tab
            label="ダイアリー"
            href={`/users/${user.login}`}
            value="diaries"
          >
            <div style={styles.tabContainer}>
              <PostsListContainer
                paginationKey={`USERS_${user.id}_DIARIES`}
                searchParam={`type=Diary&user_id=${user.id}`}
              />
            </div>
          </Tab>
          <Tab
            label="投稿した質問"
            href={`/users/${user.login}/questions`}
            value="questions"
          >
            <div style={styles.tabContainer}>
              <PostsListContainer
                paginationKey={`USERS_${user.id}_QUESTIONS`}
                searchParam={`type=Question&user_id=${user.id}`}
              />
            </div>
          </Tab>
          <Tab
            label="イベント"
            href={`/users/${user.login}/events`}
            value="events"
          >
            <div style={styles.tabContainer}>
              <PostsListContainer
                paginationKey={`USERS_${user.id}_EVENTS`}
                searchParam={`type=Event&user_id=${user.id}`}
              />
            </div>
          </Tab>
          {user.sabako_id && isSabako && (
            <Tab
              label="サバ子ちゃん"
              href={`/users/${user.login}/sabako`}
              value="sabako"
            >
              <div style={styles.tabContainer}>
                <PostsListContainer
                  paginationKey={`USERS_${user.id}_SABAKOS`}
                  searchParam={`type=Sabako&user_id=${user.id}`}
                />
              </div>
            </Tab>
          )}
        </Tabs>
      </div>
    );
  }

  render() {
    const { user, isSignedIn } = this.props;
    if (user.profile_audience !== 'public' && !isSignedIn) {
      return (
        <div style={{ overflowX: 'hidden' }}>
          {this.renderUserImage()}
          プロフィールは会員限定公開です。
        </div>
      );
    }
    return (
      <div style={{ overflowX: 'hidden' }}>
        {this.renderUserImage()}
        {this.renderTabs()}
      </div>
    );
  }
}

UserPage.propTypes = {
  user: UserPropType.isRequired,
  display: PropTypes.string,
  isSignedIn: PropTypes.bool.isRequired,
  isSabako: PropTypes.bool,
};

UserPage.defaultProps = {
  display: 'diaries',
  isSabako: false,
};

export default UserPage;
