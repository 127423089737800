import 'common/utils/polyfill';
import 'common/constants/globals.js.erb';
import addEventListenerOnTurbolinksLoad from 'common/utils/addEventListenerOnTurbolinksLoad';
import loadFacebook from 'common/utils/loadFacebook';
import loadTwitter from 'common/utils/loadTwitter';
// import loadLine from 'common/utils/loadLine';
import loadGoogleAnalytics from 'common/utils/loadGoogleAnalytics';
import showConsoleMessage from 'common/utils/showConsoleMessage';
import showSmartBanner from 'common/utils/showSmartBanner';
import configureStore from '../main/store/configureStore';
import Layout from '../main/components/layouts/Layout';
import UserLayout from '../main/components/layouts/UserLayout';
import Home from '../main/components/pages/Home';
import PostPage from '../main/components/pages/PostPage';
import PostListPage from '../main/components/pages/PostListPage';
import UserPage from '../main/components/pages/UserPage';
import UserListPage from '../main/components/pages/UserListPage';
import EditUser from '../main/components/pages/users/EditUser';
import SignUp from '../main/components/pages/users/SignUp';
import SignIn from '../main/components/pages/users/SignIn';
import ResetPassword from '../main/components/pages/users/ResetPassword';
import EditPassword from '../main/components/pages/users/EditPassword';
import TagPage from '../main/components/pages/TagPage';
import SearchPage from '../main/components/pages/SearchPage';
import NotificationPage from '../main/components/pages/NotificationPage';
import InquiryPage from '../main/components/pages/InquiryPage';
import ArticleListPage from '../main/components/pages/ArticleListPage';
import ArticlePage from '../main/components/pages/ArticlePage';
import StaticPage from '../main/components/pages/StaticPage';

const myComponents = {
  Layout,
  UserLayout,
  Home,
  PostPage,
  PostListPage,
  UserPage,
  UserListPage,
  EditUser,
  SignUp,
  SignIn,
  ResetPassword,
  EditPassword,
  TagPage,
  SearchPage,
  NotificationPage,
  InquiryPage,
  ArticleListPage,
  ArticlePage,
  StaticPage,
};

const store = configureStore();

addEventListenerOnTurbolinksLoad(store, myComponents);
loadGoogleAnalytics();
loadFacebook();
loadTwitter();
// loadLine();
showConsoleMessage();
showSmartBanner();
