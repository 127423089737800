import React from 'react';
import Paper from 'material-ui/Paper';
import ImageButton from '../../../../common/components/ImageButton';

const styles = {
  container: {
    paddingBottom: 20,
    fontSize: 14,
  },
  aboutImage: {
    width: '100%',
    height: 'auto',
    marginBottom: 10,
  },
  button: {
    marginBottom: 20,
  },
  p: {
    marginBottom: 20,
    marginLeft: 5,
    marginRight: 5,
    textAlign: 'center',
  },
  header: {
    marginTop: 30,
    marginBottom: 30,
    marginLeft: 5,
    marginRight: 5,
    textAlign: 'center',
    color: '#00f',
    fontWeight: 'bold',
    fontSize: 17,
  },
  caution: {
    marginBottom: 30,
    marginLeft: 5,
    marginRight: 5,
    textAlign: 'center',
    fontSize: 13,
    color: '#f00',
  },
  ul: {
    marginBottom: 20,
    marginLeft: 5,
    marginRight: 5,
    paddingLeft: 20,
  },
  li: {
    listStyleType: 'disc',
  },
};

const images = global.PeerRing.images.about;
const AboutPage = () => (
  <Paper style={styles.container}>
    <img
      src={images[0]}
      style={styles.aboutImage}
      alt="Peer Ringとは？"
    />
    <p style={styles.p}>
      Peer Ring （ピアリング）は、
      <br />
      乳がん・子宮がん・卵巣がんなど
      <br />
      女性のがんに向き合う方のための
      <br />
      ピアサポート・コミュニティ。
      <br />
      無料会員制のＳＮＳです。
    </p>
    <p style={styles.p}>
      がんと診断されてこれから治療を始める方。
      <br />
      治療中の方。 経過観察中の方。
      <br />
      いつでも、どこでも
      <br />
      「自分と同じ病気、似た状況」
      <br />
      にある仲間とつながりささえあえる
      <br />
      コミュニティです。
    </p>
    <img
      src={images[1]}
      style={styles.aboutImage}
      alt="Peer=同じ悩みや辛さを共有する仲間 Ring=寄り添いサポートし合う輪"
    />
    <div style={styles.header}>☆Peer Ringでできること☆</div>
    <img
      src={images[2]}
      style={styles.aboutImage}
      alt="Peer Ringでできること　ダイアリー"
    />
    <p style={styles.p}>
      治療のこと、日々のがんばり・想いなど
      <br />
      自由に記録してみましょう。
      <br />
      コメント機能で気軽にメンバーと
      <br />
      コミュニケーションを
      <br />
      取ることができます。
    </p>
    <p style={styles.p}>
      同じ病気に直面する、誰かにとって、
      <br />
      あなたの投稿が励みになる
      <br />
      かもしれません。
    </p>
    <img
      src={images[3]}
      style={styles.aboutImage}
      alt="Peer Ringでできること　質問"
    />
    <p style={styles.p}>
      ボディケアやウィッグ、下着のこと、
      <br />
      副作用への対処法などなど・・・
      <br />
      診察室未満のモヤモヤを、
      <br />
      メンバーに投げかけてみましょう！
    </p>
    <p style={styles.p}>
      過去に似た質問があっても大丈夫。
      <br />
      同じ治療を先に経験した方や、
      <br />
      似た状況にある方に、
      <br />
      気軽に質問することができます。
    </p>
    <p style={styles.caution}>
      ※ 病状の判断や治療の方針、
      <br />
      お薬の選択のことなど、
      <br />
      医療的判断そのものに関わることは、
      <br />
      Peer Ring（ピアリング）では質問できません。
      <br />
      主治医の先生としっかり相談しましょう。
    </p>
    <p style={styles.p}>
      Peer Ring オリジナルのよみものも
      <br />
      お楽しみいただけます。
      <br />
      治療の副作用を乗り切るコツなど、
      <br />
      サバイバーの皆さまからの
      <br />
      情報を中心に随時発信していきます。
    </p>
    <p style={styles.caution}>
      ※ダイアリーや質問を投稿したり、
      <br />
      非公開の投稿を閲覧するためには、
      <br />
      会員登録が必要です。
    </p>
    <ImageButton
      src={images[4]}
      alt="新規会員登録"
      href="/users/sign_up"
      style={styles.button}
    />
    <div style={styles.header}>
      ☆Peer Ringの目指すこと☆
    </div>
    <ul style={styles.ul}>
      <li style={styles.li}>
        治療をがんばっている自分、病気に向き合う自分を記録したい
      </li>
      <li style={styles.li}>
        自分と似た状況の人とつながり、はげましあいたい
      </li>
      <li style={styles.li}>
        つらい状況を乗り越えた今、過去の自分と同じように闘っている仲間を応援したい
      </li>
    </ul>
    <p style={styles.p}>
      Peer Ring はそんな想いをカタチにする
      <br />
      コミュニティです。
    </p>
    <p style={styles.p}>
      あなたの参加をお待ちしています。
    </p>
  </Paper>
);

export default AboutPage;
