const analyticsId = 'UA-99630352-1';

const trackPageview = () => {
  if (global.PeerRing.RailsEnv === 'production') {
    global.ga('send', 'pageview');
  }
};

const loadGoogleAnalytics = () => {
  /* eslint-disable */
  (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
  (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
  m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
  })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
  /* eslint-enable */

  if (global.PeerRing.user) {
    global.ga('create', analyticsId, 'auto', { userId: global.PeerRing.user.login });
  } else {
    global.ga('create', analyticsId, 'auto');
  }

  if (global.Turbolinks && global.Turbolinks.supported) {
    document.addEventListener('turbolinks:load', () => trackPageview(), true);
  } else {
    trackPageview();
  }
};

export default loadGoogleAnalytics;
