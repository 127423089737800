import PropTypes from 'prop-types';

export const UserPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  profile: PropTypes.string,
  login: PropTypes.string,
  sabako_id: PropTypes.string,
});

export const ImagePropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  file_url: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
});

export const PostPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  user: UserPropType.isRequired,
  type: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  comments: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    content: PropTypes.string,
    created_at: PropTypes.string,
  })).isRequired,
  commented_at: PropTypes.string.isRequired,
  category: PropTypes.string,
  tag_list: PropTypes.arrayOf(PropTypes.string).isRequired,
  likes: PropTypes.shape({
    users: PropTypes.shape({}).isRequired,
  }).isRequired,
  images: PropTypes.arrayOf(ImagePropType),
  audience: PropTypes.string.isRequired,
  is_favorite: PropTypes.bool,
  created_at: PropTypes.string.isRequired,
});

export const CategoryPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
});

export const NotificationPropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  notificationable: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
});

export const ArticlePropType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
});

export const AdsPropType = PropTypes.shape({});

export const AdPropType = PropTypes.shape({});
