import SmartBanner from 'smart-app-banner';
import '../sass/smartbanner.scss';

export default function showSmartBanner() {
  if (global.PeerRing.embeded) {
    return;
  }
  /* eslint-disable no-new */
  new SmartBanner({
    daysHidden: 1, // days to hide banner after close button is clicked (defaults to 15)
    daysReminder: 1, // days to hide banner after "VIEW" button is clicked (defaults to 90)
    appStoreLanguage: 'jp', // language code for the App Store (defaults to user's browser language)
    title: 'Peer Ring ピアリング',
    author: 'Risa Sana, Inc',
    button: '開く',
    store: {
      ios: 'App Store',
      android: 'Google Play',
    },
    price: {
      ios: '無料',
      android: '無料',
    },
  });
  /* eslint-enable no-new */
}
