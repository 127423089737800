import message from './consoleMessage.txt';

function showConsoleMessage() {
  const t = setTimeout(() => {
    /* eslint-disable no-console */
    console.log(`%c${message}`, 'color: rgb(240, 98, 146); font-size: small;');
    clearTimeout(t);
    console.log('%cPeer Ring 〜 なかまと話そう！女性特有のがん', 'font-size: x-large;');
    console.log('「つらい闘病生活に直面し不安を抱えながら生活している女性・病気のことを話したいのに、' +
    '周りに話せる人がいない苦しさを感じている女性が、このPeer Ringでつらさを共有し分かち合える仲間を見つけ、' +
    '互いにはげましあい支え合って、元気を取り戻してほしい」\nこんな思いで、Peer Ringをつくりました。');
    /* eslint-enable */
  }, 1000);
}

export default showConsoleMessage;
